<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm
          :fields="this.prediction_model.fields"
          :card="this.prediction_model.card"
        >
          <template v-slot:footer>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <BrunaForm
          :fields="this.parameters_model.fields"
          :card="this.parameters_model.card"
        >
          <template v-slot:footer>
          </template>
        </BrunaForm>
        
        
        </b-col>
    </b-row>

    <!--
    <ag-grid-table
      ref="grid-historico-freights"
      :configUrl="historicoFreightsConfig"
      :dataUrl="historicoFreightsData"
      :editUrl="historicoFreightsEdit"
    ></ag-grid-table>
    -->


  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'


import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";



import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";



export default {
  data()
  {
    return {


      historicoFreightsConfig: useApiServices.historicoFreightsConfig,
      historicoFreightsData: useApiServices.historicoFreightsData,
      historicoFreightsEdit: useApiServices.historicoFreightsEdit,




      prediction_model: {
        card: {
          title: "Modelo de Prediccion",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Modelo",
            type: "input",
            id: 'modelo',
            rules: 'required',
            disabled: true,
            format: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            value: "Sarimax"
          },

          {
            label: "Tipo de Modelo",
            type: "input",
            id: 'tipo_modelo',
            rules: 'required',
            disabled: true,
            format: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            value: "Multivariable"
          },

          {
            label: "Descripcion",
            type: "textarea",
            id: 'descripcion',
            rules: 'required',
            disabled: true,
            format: {
              xs: 12,
              sm: 12,
              md: 12,
              lg: 12,
              xl: 12
            },
            value: "Modelo Sarimax (modelos multivariante) se utiliza cuando necesitamos reflejar la interrelación entre variables de distintas series temporales, luego estos modelos contienen varias series temporales.\n\n Concretamente estudian la relación entre una variable de interés y una serie de variables explicativas (que influyen en la variable de interés). En el marco multivariante se considera el pasado tanto de la variable que se quiere explicar, como el de las variables que están relacionadas con dicha variable.\n\n El análisis de series temporales multivariadas considera simultáneamente series temporales múltiples.\n\n En general, mucho más complicado que el análisis de series de tiempo univariante, especialmente cuando el número de series consideradas es grande.\n\n Toma de decisiones. Los objetivos del análisis de series temporales multivariadas incluyen, por lo tanto:\n\n 1) Mejorar la precisión de la predicción.\n\n 2) Estudiar las relaciones dinámicas entre variables."
          },

         

        ]
      },

    parameters_model: {
        card: {
          title: "Parametros Utilizados",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Past History",
            type: "input",
            id: 'past_history',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "78"
          },


          {
            label: "Future Target",
            type: "input",
            id: 'future_target',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "52"
          },
         

          {
            label: "Estado",
            type: "input",
            id: 'estado',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "Active"
          },

          {
            label: "Variable Objetivo",
            type: "input",
            id: 'variable_objetivo',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "price_calibre"
          },


          {
            label: "Train Shape",
            type: "input",
            id: 'train_shape',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "259"
          },

          {
            label: "Input Model",
            type: "input",
            id: 'input_model',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "ts_train"
          },      
          
          {
            label: "Past History",
            type: "input",
            id: 'past_history',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "78"
          },            

          {
            label: "Future Target",
            type: "input",
            id: 'future_target',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "52"
          },    

          {
            label: "Param Regular",
            type: "input",
            id: 'param_regular',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "1,0,1"
          },              


          {
            label: "Param Seasonal",
            type: "input",
            id: 'param_seasonal',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "1,1,1,78"
          },     

          {
            label: "N Test",
            type: "input",
            id: 'n_test',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "2"
          },             

          {
            label: "Steps",
            type: "input",
            id: 'steps',
            rules: 'required',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "n_tests + future_target"
          },    
        ]
      },







    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    AgGridTable,
    BrunaForm,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {

  },


  created()
  {



  },

  methods: {



    freightChange(field)
    {
      console.log(field)

 

    },

  


  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

